<template lang="pug">
.h-auto.flex.w-full(
  id="divLoadingAuth"
)
  vx-card()
    div.text-center
      h1(v-if="$can('create', 'users')" ) Dashboard
      vs-button(
        v-if="false"
        class="mt-6 block offset-3"
        color="primary"
        type="filled"
        @click="exportcsv"
      ) Conectar Socket
      vs-button(
        v-if="false"
        class="mt-6 block offset-3"
        color="primary"
        type="filled"
        @click="clickButton"
      ) Suscribir a update gps
      vs-button(
        v-if="false"
        class="mt-6 block offset-3"
        color="primary"
        type="filled"
        @click="clickButton3"
      ) Emitir
</template>
<script>

import HTTP from "@/http-common"//eslint-disable-line
const axios = require('axios');
export default {
  sockets: {
    'connect'() {
      console.log('socket connected')
    },
    'reconnect_attempt'() {
      console.log('reconnect_attempt')
    },
    // update_gps(val) {
    //   console.log('update_gps', val)
    // }
  },
  mounted() {
    this.sockets.subscribe('service_update', (payload) => {
      console.log('Hola soy un metodo', JSON.parse(payload))
    });
    var config = {
      method: 'get',
      url: 'https://x0fwsnn6pg.execute-api.us-east-1.amazonaws.com/v1/smartquick/pwa?file_name=E6SXdKJVkAUkeJi.jpg',
      headers: {
        'x-api-key': 'OgLsqlB2ym2RTkyidgMk41TCczKDseBFaHgOvxXs',
      },
    };
    axios(config).then((response) => {
      // console.log(response)
      var blob = new Blob( [ response.data.data ], { type: "image/jpeg" } );
      var imageUrl = URL.createObjectURL( blob );
      console.log(imageUrl)
    })
    
    // this.sockets.unsubscribe('elquequieras');
  },
  methods: {
    async exportcsv() {
      const headers = {
        estado: 'estado',
        guia: 'guia',
        cod_sim_MSISDN: 'cod_sim_MSISDN',
        cod_sim_IMSI: 'cod_sim_IMSI',
        documento: 'documento',
        destinatario: 'destinatario',
        cx: 'cx',
        cy: 'cy',
        fecha_entrega: 'fecha_entrega',
        hora_entrega: 'hora_entrega',
        direccion: 'direccion',
        ciudad: 'ciudad',
        telefono: 'telefono',
        celular: 'celular',
        responsable: 'responsable',
        tipo_visita: 'tipo_visita',
        cantidad_sim_card: 'cantidad_sim_card',
        plan: 'plan',
        valor_a_recaudar: 'valor_a_recaudar',
        canal: 'canal',
        quien_firma: 'quien_firma',
        encuesta1: 'encuesta1',
        encuesta2: 'encuesta2',
        encuesta3: 'encuesta3',
        estado_geo: 'estado_geo',
        restriccion: 'restriccion',
        fecha_creacion: 'fecha_creacion',
        fecha_aceptacion: 'fecha_aceptacion',
        fecha_llegada: 'fecha_llegada',
        fecha_cierre: 'fecha_cierre',
        novedad: 'novedad',
        observacion: 'observacion',
        foto: 'foto',
        firma: 'firma',
        anoexpedicion: 'anoexpedicion',
        linea_migrar: 'linea_migrar',
        nip: 'nip',
        id_vendedor: 'id_vendedor',
        estado_archivo: 'estado_archivo',
        fecha_televenta: 'fecha_televenta',
        usuario_modificador: 'usuario_modificador',
        campana: 'campana',
        email: 'email',
        IMSI: 'IMSI',
        MSISDN: 'MSISDN',
        jornada: 'jornada',
        contact_center: 'contact_center',
        usuario_creador: 'usuario_creador',
        forma_pago: 'forma_pago',
        cedula_responsable: 'cedula_responsable'
      };
      const data = await HTTP.get(`https://integracion.smartquick.com.co/exporteRecursos_prueba.php?fecha1=2021-03-01&fecha2=2021-03-31`).then((response) => {
        // console.log(response)
        return response.data
      })
      this.exportCSVFile(headers, data, 'nombres')
    },
    convertToCSV(objArray) {
      const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
        if (line != "") line += ";";
      line += array[i][index];
        }
      str += line + "\r\n";
      }
      return str;
    },
    exportCSVFile(headers, items, fileName) {
      if (headers) {
        items.unshift(headers);
      }
      const jsonObject = JSON.stringify(items);
      const csv = this.convertToCSV(jsonObject);
      const exportName = fileName + ".csv" || "export.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
      }
    },
    clickButton3() {
      // this.$socket.client is `socket.io-client` instance
      // console.log(this.$socket.connected, val)   
      // this.$socket.emit('chat message', 'Hola mundo 2')
      // this.$socket.emit('elquequieras', 'Hola mundo soy una prueba')
      // this.sockets.subscribe('PLACA123', (payload) => {
      //   console.log('Hola soy un metodo', payload)
      // });
    },
    clickButton() {
      this.$socket.emit('joinRoom', 'canalprueba2')
      // this.$socket.client is `socket.io-client` instance
      // console.log(this.$socket.connected, val)
      // this.$socket.emit('chat message', 'Hola mundo 2')
      // this.sockets.subscribe('update_gps', (payload) => {
      //   console.log('Hola soy un metodo', JSON.parse(payload))
      // });
    },
    clickButton2() {
      // this.$socket.client is `socket.io-client` instance
      this.$socket.io.opts.query = {
        token: localStorage.getItem('TokenSocket')
      }
      console.log(this.$socket.io)
      this.$socket.open()
      // this.$socket.emit('chat message', 'Hola mundo 2')
      // this.$socket.close()
    },
    sortJSON(data, key, orden) {
      return data.sort(function (a, b) {
          var x = a[key],
          y = b[key];

          if (orden === 'asc') {
              return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          }

          if (orden === 'desc') {
              return ((x > y) ? -1 : ((x < y) ? 1 : 0));
          }
      });
    }
  }
}
</script>